import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import EventListItem from "../components/ListItems/EventListItem";
import Breadcrumb from "../components/Breadcrumb";
import Title from "../components/Title";

const EventList = ({pageContext, data, location}) => {
  const {
    currentPage,
    numPages,
  } = pageContext

  return (
    <Layout>
      <Metas title="Agenda"/>
      <section className="section page-content">
        <div className="container mx-auto px-4">
          <Breadcrumb/>

          <Title title="Agenda" className="text-primary"/>

          <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {data.events.nodes.map((node, index) => (
              <div key={index} className="column event">
                <EventListItem content={node} />
              </div>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </div>
      </section>
    </Layout>
  )
}

export default EventList

export const query = graphql`
    query eventListQuery($skip: Int!, $limit: Int!) {
        events: allNodeEvenement(
            filter: {
                status: {eq: true}, 
                is_forthcoming: {eq: true}
            }
            sort: {fields: field_date___value, order: ASC}
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                id
                title
                dates: field_date {
                    startDay: value(formatString: "DD")
                    startMonth: value(formatString: "MM")
                }
                body {
                    summary
                    value
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 464, maxHeight: 294, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                path {
                    alias
                }
            }
        }
    }`
